import { Controller } from "stimulus"

export default class TableController extends Controller {

  static targets = [ 'table', 'search' ]
  static classes = []
  static values = { tableState: String }

  element: HTMLElement
  tableTarget:HTMLElement
  searchTarget:HTMLInputElement

  tableStateValue:string

  connect() {
    this.searchTarget.focus();
  }

  search(event) {
    // let target:HTMLInputElement = event.currentTarget;
    let tableState:Object = JSON.parse(this.tableStateValue);
    tableState['search'] = this.searchTarget.value;
    let newTableState:Object = this.resetSearch(tableState);
    console.log(newTableState);
    this.updateTableState(newTableState);
  }

  triggerSearchOnEnter(event) {
    if (event.keyCode === 13) {
      this.search(event);
    }
  }

  clearSearch(event) {
    let target:HTMLInputElement = event.currentTarget;
    let tableState:Object = JSON.parse(this.tableStateValue);
    this.searchTarget.value = '';
    this.search(event);
  }

  resetSearch(tableState):Object {
    delete tableState['index'];
    delete tableState['current_page'];
    delete tableState['pagination_action'];
    return tableState;
  }

  changeLimit(event) {
    let target:HTMLSelectElement = event.currentTarget;
    let tableState:Object = JSON.parse(this.tableStateValue);
    let newOptions:Object = { limit: parseInt(target.options[target.selectedIndex].value) };
    tableState = Object.assign({}, tableState, newOptions);
    this.updateTableState(tableState);   
  }

  changePage(event) {
    let target:HTMLElement = event.currentTarget;
    let tableState:Object = JSON.parse(this.tableStateValue);
    let targetIndex:number = parseInt(target.dataset.targetIndex);
    let paginationAction:string = target.dataset.paginationAction;
    let newOptions:Object = { index: targetIndex, pagination_action: paginationAction };
    tableState = Object.assign({}, tableState, newOptions);
    this.updateTableState(tableState);
  }

  changeSortOrder(event) {
    let target:HTMLElement = event.currentTarget;
    let tableState:Object = JSON.parse(this.tableStateValue);
    let newOptions:Object = { sort_order: (tableState['sort_order'] == 'asc' ? 'desc' : 'asc'), current_page: 1, last_page: null, first_page: null, index: null };
    tableState = Object.assign({}, tableState, newOptions);
    this.updateTableState(tableState);
  }

  updateTableState(tableState):void {
    let encodedTableState:string = btoa(JSON.stringify(this.mergeTableStates(tableState)));
    console.log(this.modifyUrl(encodedTableState));
    window.location.assign(this.modifyUrl(encodedTableState).href)
  }

  modifyUrl(encodedTableState):URL {
    let url:URL = new URL(window.location.href);
    url.searchParams.set('table_state', encodedTableState )
    return url;
  }

  mergeTableStates(tableState):Object {
    let newTableStates:Object = this.tableStates();
    newTableStates[this.element.id] = tableState;
    return newTableStates;
  }

  tableStates():Object {
    let tableStates:Object = {}
    const tableControls = document.querySelectorAll('.table-control')
    tableControls.forEach(function (tableControl: HTMLElement) {
      tableStates[tableControl.id] = JSON.parse(tableControl.dataset.tableTableStateValue)
    });
    return tableStates;
  }


}