import { Controller } from 'stimulus'

export default class ModalController extends Controller {
  
  static values = { modal: String }
  static classes = [ 'active' ]

  element: HTMLElement
  modalValue: string
  activeClass: string

  open() {
    const modalId = this.modalValue;
    document.getElementById(modalId).classList.toggle(this.activeClass);
  }

  close() {
    this.element.closest('.modal').classList.toggle(this.activeClass);
  }
}