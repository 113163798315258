import { Application, Controller } from 'stimulus'
import { throttle, debounce } from "throttle-debounce";
import gasImage from '../../images/gas.svg'
import electricityImage from '../../images/electricity.svg'

export default class extends Controller {
  
  static targets = [ 'input' ]
  static values = { url: String }

  element: HTMLElement
  application: Application
  inputTarget: HTMLInputElement
  urlValue: string
  abortController: AbortController
  results: Array<Object>
  throttledSearch: Function
  debouncedSearch: Function
  resultsContainer: HTMLElement

  initialize() {
    
  }

  connect() {
    this.throttledSearch = throttle(1000, this.fetchResults)
    this.debouncedSearch = debounce(1000, this.fetchResults)
    this.createResultsContainer()
  }

  search() {
    const query = this.inputTarget.value
    // if (query.length < 3) {
    //   this.throttledSearch(query)
    //   console.log(`throttled search for ${query}`)
    // } else {
    //   this.debouncedSearch(query)
    //   console.log(`debounced search for ${query}`)
    // }

    if (query.length > 2) {
      this.debouncedSearch(query)
      // console.log(`debounced search for ${query}`)
    }
  }

  fetchResults(query) {
    const url = new URL(this.urlValue)
    url.searchParams.append("query", query)
    this.abortController = new AbortController()
    fetch(url.toString(), { signal: this.abortController.signal })
      .then(response => response.text())
      .then(json => {
        this.results = JSON.parse(json)
        console.log(this.results)
        this.renderResults()
      })
      .catch(() => {})
    
  }

  createResultsContainer() {
    this.resultsContainer = document.createElement("div");
    this.resultsContainer.setAttribute("class", "search-results");
    this.element.after(this.resultsContainer);
  }

  renderResults() {
    this.resultsContainer.remove()
    this.createResultsContainer()
    if (this.results.length == 0) {
      this.resultsContainer.innerHTML = "No results";
    } else {
      this.results.forEach(
        (result, index) => { this.resultsContainer.innerHTML += this.resultItem(result, index)} 
      )
    }
  }

  // resultItem(result, index) {
  //   return `<div class="${['search-result', result.class].join(' ')}">
  //             <p>
  //               <a href="${result.url}">${result.label}</a>
  //               <br />
  //               <small>${result.meta}</small>
  //             </p>
  //           </div>`
  // }

  resultItem(result, index) {
    let image = result.fuel_type == 'GAS' ? gasImage : electricityImage
    console.log(image)
    return `<article class="media">
              <figure class="media-left">
                <p class="image is-32x32">
                  <img src="${image}">
                </p>
              </figure>
              <div class="media-content">
                <div class="content">
                  <a href="${result.url}">${result.label}</a>
                  <br />
                  <small>${result.meta}</small>
                </div>
              </div>
              <div class="media-right">
              </div>
            </article>`
  }

  emptyResultItem() {
    return `<div class='search-result'><p>No results</p></div>`
  }

  clear(event) {
    location.reload()
    // this.resultsContainer.remove()
    // this.inputTarget.value = ''
    // this.results = []
    if (this.abortController) { this.abortController.abort() }
  }

  removeNode(nodeId) {
    let node: HTMLElement = this.element.parentNode.querySelector(nodeId)
    if (node) { node.remove() }
  }

}