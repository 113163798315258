import { Controller } from 'stimulus'

export default class TabsController extends Controller {

  element: HTMLElement
  
  connect() {
    this.setTabVisibility();
  }

  open(event: CustomEvent) {
    const eventTarget = event.currentTarget as HTMLElement
    this.getTabs().forEach(function (tab: HTMLElement) {
      tab === eventTarget.parentNode ? tab.classList.add('is-active') : tab.classList.remove('is-active')
    })
    this.setTabVisibility();
  }

  setTabVisibility() {
    this.getTabs().forEach(function (tab: HTMLElement) {
      const contentId = tab.dataset.tabsContentValue as string
      const content = document.querySelector('#' + contentId) as HTMLElement
      if (tab.classList.contains('is-active')){
        content.style.visibility = 'visible';
        content.style.display = 'block';
      } else {
        content.style.visibility = 'hidden';
        content.style.display = 'none';
      }
    })
  }

  getTabs() {
    return this.element.querySelectorAll('li');
  }

}