import { Controller } from 'stimulus'

export default class FormController extends Controller {
  
  static targets = [ ]

  element: HTMLElement

  connect() {
    // this.element.setAttribute('novalidate', 'true');
  }

  disconnect() {}

}