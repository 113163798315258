import { Application, Controller } from "stimulus"
import { DataTable } from "simple-datatables/src"
// import { DataTable } from "/Users/jon/Documents/apps/forks/Simple-DataTables/src"

export default class extends Controller {

  static targets = [ 'table' ]
  // static classes = []
  static values = { json: String, options: String }

  element: HTMLElement
  application: Application
  tableTarget: HTMLElement
  optionsValue: string
  dataTable: DataTable
  defaultOptions: Object
  tableOptions: Object
  options: Object
  self: Controller

  initialize() {
    
  }

  connect() {
    let self = this

    this.defaultOptions = {
      paging: true,
      fixedHeight: false,
      firstLast: true,
      // footer: true
    }

    // options can be overridden 
    // N.B. There is an issue rendering two tables on the same page where one is rendered from Ajax
    // Also - styling is not as easy as with a rails view as needs to be done
    // via the function specified by the ajax 'load' parameter
    // to use ajax, set data-datatables-json-value="/path/to/json" attribute on the controller
    // let ajaxOptions:Object = this.jsonValue ? { ajax: { url: this.jsonValue, load: this.processAjax } } : {}
    // let ajaxOptions:Object = this.jsonValue ? { ajax: { url: this.jsonValue } } : {}
    this.tableOptions = this.optionsValue ? JSON.parse(this.optionsValue) : {}
    this.options = Object.assign({}, this.defaultOptions, this.tableOptions);
    // console.log("OPTIONS: " + JSON.stringify(options));

    this.dataTable = new DataTable(this.tableTarget, this.options);

    this.dataTable.on('datatable.init', function(){

      self.changeHeader();
      self.addSortArrows();
      self.overwritePagination();
      let o = self.dataTable.options

      // Re-attach the perPage event
      let selector = self.dataTable.wrapper.querySelector('.dataTable-selector')
      selector.addEventListener("change", function() {
        o.perPage = parseInt(this.value, 10), self.dataTable.update(), self.dataTable.fixHeight(), self.dataTable.emit("datatable.perpage", self.dataTable.perPage);
      })
      // Re-attach the search event
      let searchInput = self.dataTable.wrapper.querySelector('.dataTable-input')
      searchInput.addEventListener("keyup", function() {
        self.dataTable.search(this.value);
      })
      // Hide the selector unless paging
      if (o.paging == false) {
        let selectorControl = self.dataTable.wrapper.querySelectorAll('.selector-control');
        selectorControl.forEach(function (el) { el.style.display="none" });
      }

    })

    // Events
    this.dataTable.on('datatable.page', function(){
      self.overwritePagination();
    })
    this.dataTable.on('datatable.refresh', function(){
      self.overwritePagination();
      // self.addSortArrows();
    })
    // this.dataTable.on('datatable.update', function(){
    //   self.overwritePagination();
    // })
    this.dataTable.on('datatable.sort', function(){
      self.addSortArrows();
    })
    // this.dataTable.on('datatable.perpage', function(){
    //   self.overwritePagination();
    // })
    // this.dataTable.on('datatable.search', function(){
    //   self.overwritePagination();
    // })

  }

  addSortArrows() {
    const sortLinks = this.dataTable.wrapper.querySelectorAll('.dataTable-sorter')
    sortLinks.forEach((link) => {
      let oldIcon = link.querySelector('.icon')
      let icon:string;
      if (oldIcon) { oldIcon.remove() }

      if (link.parentNode.classList.contains('asc')) {
        icon = this.featherIcon('chevron-up', 'icon--table-sort')
      } else if  (link.parentNode.classList.contains('desc')) {
        icon = this.featherIcon('chevron-down', 'icon--table-sort')
      }
      if (icon !== undefined) {
        link.innerHTML = `<span>${link.innerHTML}</span>${icon}`
      }
    })
  }

  changeHeader() {
    let element = this.dataTable.wrapper.querySelector('.dataTable-top')
    element.innerHTML = `
    <nav class="level">
      <div class="level-left">
        <div class="level-item selector-control">
          <div class="select">
            <select class="dataTable-selector">
              <option value="5">5</option>
              <option value="10" selected="">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <div class="level-item  selector-control">
          <p>rows per page</p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="dataTable-search">
            <input class="input dataTable-input" placeholder="Search" type="search">
          </div>
        </div>
      </div>
    </nav>`
  }

  // This is the default pagination
  // <div class="dataTable-pagination">
  //   <li class="pager"><a href="#" data-page="1">«</a></li>
  //   <li class="pager"><a href="#" data-page="1">‹</a></li>
  //   <li class="active"><a href="#" data-page="1">1</a></li>
  //   <li class=""><a href="#" data-page="2">2</a></li>
  //   <li class=""><a href="#" data-page="3">3</a></li>
  //   <li class=""><a href="#" data-page="4">4</a></li>
  //   <li class=""><a href="#" data-page="5">5</a></li>
  //   <li class=""><a href="#" data-page="6">6</a></li>
  //   <li class=""><a href="#" data-page="7">7</a></li>
  //   <li class="ellipsis"><a href="#">…</a></li>
  //   <li class=""><a href="#" data-page="100">100</a></li>
  //   <li class="pager"><a href="#" data-page="2">›</a></li>
  //   <li class="pager"><a href="#" data-page="100">»</a></li>
  // </div>

  // Bulma compatible pagination
  // <nav class="pagination is-centered" role="navigation" aria-label="pagination">
  //   <a class="pagination-previous">Previous</a>
  //   <a class="pagination-next">Next page</a>
  //   <ul class="pagination-list">
  //     <li><a class="pagination-link" aria-label="Goto page 1">1</a></li>
  //     <li><span class="pagination-ellipsis">&hellip;</span></li>
  //     <li><a class="pagination-link" aria-label="Goto page 45">45</a></li>
  //     <li><a class="pagination-link is-current" aria-label="Page 46" aria-current="page">46</a></li>
  //     <li><a class="pagination-link" aria-label="Goto page 47">47</a></li>
  //     <li><span class="pagination-ellipsis">&hellip;</span></li>
  //     <li><a class="pagination-link" aria-label="Goto page 86">86</a></li>
  //   </ul>
  // </nav>

  overwritePagination() {
    const wrapper: HTMLElement = this.dataTable.wrapper
    const bottomDiv: HTMLElement = wrapper.querySelector('.dataTable-bottom')
    const paginationDiv: HTMLElement = bottomDiv.querySelector('.dataTable-pagination')
    const infoDiv: HTMLElement = bottomDiv.querySelector('.dataTable-info')
    const oldFooter: HTMLElement = bottomDiv.querySelector('.table__pagination')
    const onFirstPage = this.dataTable.onFirstPage
    const onLastPage = this.dataTable.onLastPage

    let customFooter: HTMLElement = document.createElement("div")
    // customFooter.setAttribute('id', 'table__pagination')
    customFooter.classList.add('level', 'table__pagination')
    customFooter.innerHTML = this.customFooter();
    let nav: HTMLElement = customFooter.querySelector('nav')

    let info: HTMLElement = customFooter.querySelector('div.info')
    if (infoDiv !== null) { info.innerHTML = infoDiv.innerHTML }
    
    let ul = document.createElement("ul");
    ul.classList.add('pagination-list');

    // let next = document.createElement("a")
    // next.classList.add('pagination-next', 'pager')
    // next.innerHTML = 'Next'

    let pagers = paginationDiv.querySelectorAll("li.pager")
    pagers.forEach((p, i) => {
      let pageNumber = p.firstElementChild.getAttribute('data-page')
      let button = document.createElement("a")
      button.setAttribute('data-page', pageNumber)
      button.setAttribute('href', '#')
      button.classList.add('pager')
      if ( pagers.length == 4 && i == 0 ) {
        button = this.pagerButton(button, 'pagination-previous', 'chevrons-left');
        if (onFirstPage) { button.setAttribute('disabled', 'disabled') }
      } else if ( (pagers.length == 4 && i == 1) || (pagers.length == 2 && i == 0) ) {
        button = this.pagerButton(button, 'pagination-previous', 'chevron-left');
        if (onFirstPage) { button.setAttribute('disabled', 'disabled') }
      } else if ( (pagers.length == 4 && i == 2) || (pagers.length == 2 && i == 1) ) {  
        button = this.pagerButton(button, 'pagination-next', 'chevron-right');
        if (onLastPage) { button.setAttribute('disabled', 'disabled') }
      } else if (pagers.length == 4 && i == 3) {
        button = this.pagerButton(button, 'pagination-next', 'chevrons-right');
        if (onLastPage) { button.setAttribute('disabled', 'disabled') }
      }
      nav.appendChild(button)
    })

    let pages = paginationDiv.querySelectorAll("li:not(.pager)")
    pages.forEach((p, i) => {
      let pageNumber = p.firstElementChild.getAttribute('data-page')
      let li = document.createElement("li") 
      if (p.classList.contains('ellipsis')) {
        let span = document.createElement("span")
        span.classList.add('pagination-ellipsis')
        span.innerHTML = '&hellip;'
        li.appendChild(span)
        ul.appendChild(li)
      } else {
        let link = document.createElement("a")
        link.classList.add('pagination-link')
        if (p.classList.contains('active'))  {
          link.classList.add('is-current')
        }
        link.setAttribute('href', '#')
        link.setAttribute('aria-label', `Go to page ${pageNumber}`)
        link.setAttribute('data-page', pageNumber)
        link.innerHTML = pageNumber
        li.appendChild(link)
        ul.appendChild(li)
      }
    })

    nav.appendChild(ul);
    paginationDiv.style.visibility = 'hidden';
    paginationDiv.style.display = 'none';
    infoDiv.style.visibility = 'hidden';
    infoDiv.style.display = 'none';
    if (oldFooter) {
      bottomDiv.replaceChild(customFooter, oldFooter)
    } else {
      bottomDiv.appendChild(customFooter)
    }
  }

  pagerButton(button, newClass, icon) {
    button.classList.add(newClass)
    button.innerHTML = this.featherIcon(icon, 'icon--table-page')
    return button
  }

  featherIcon(icon, iconClass) {
    let svgClasses = ['icon--feather']
    if (iconClass !== null) { svgClasses.push(iconClass) }
    return `<span class="icon"><svg class="${svgClasses.join(' ')}"><use xlink:href="/node_assets/feather-icons/feather-sprite.svg#${icon}"></use><svg></span>`
    // return `<span class="icon"><img class="feather" src="/node_assets/feather-icons/${icon}.svg"></span>`
  }

  customFooter() {
    return `
      <div class="level-left">
        <div class="level-item info">
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <nav role="navigation" aria-label="pagination" class="pagination is-centered is-rounded is-small" />
        </div
      </div>`
  }
  
  // Function to post-process any ajax data
  processAjax = function(xhr): string {
    // console.log(xhr)
    let data = JSON.parse(xhr.responseText);
    let regex: RegExp = /rails/i;
    for (let i = 0; i < data.length; i++) {
      for (let p in data[i]) {
        if ( data[i][p].toString().match(regex) ) {
            data[i][p] = "<u style='color:red;'>" + data[i][p] + "</u>"
        }
      } 
    }
    return JSON.stringify(data)
  }
  
}