import { Controller } from 'stimulus'

export default class FileUploadController extends Controller {
  
  static targets = [ 'fileInput', 'fileName' ]

  fileInputTarget: HTMLInputElement
  fileNameTarget: HTMLElement

  populateFilename() {
    if (this.fileInputTarget.files.length > 0) {
      this.fileNameTarget.textContent = this.fileInputTarget.files[0].name;
    }
  }

}