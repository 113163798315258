import { Controller } from 'stimulus'

export default class NavbarController extends Controller {
  
  static targets = [ 'burger', 'menu' ]
  static classes = [ 'active' ]

  burgerTarget: HTMLElement
  menuTarget: HTMLElement
  activeClass: string

  showMenu () {
    this.burgerTarget.classList.toggle(this.activeClass);
    this.menuTarget.classList.toggle(this.activeClass);
  }

}