import { Controller } from 'stimulus'

export default class NotificationController extends Controller {
  
  element: HTMLElement

  close() {
    this.element.parentNode.removeChild(this.element);
  }

}